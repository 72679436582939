import queries from '@volvo-cars/css/media-queries';

import { Sizes } from '../src/types';

/**
 * Generate a specific sizes attribute value to further help browsers determine
 * which image size to load.
 *
 * @param {Sizes | undefined} sizes
 * @returns	{string}
 */
export function generateSizesString(sizes: Sizes | undefined): string {
  let generatedSizes = 'min(80rem, 100vw)';

  if (sizes?.sm) {
    const smSize = getSizesString(sizes.sm);
    generatedSizes = smSize;
  }

  if (sizes?.md) {
    const mdSize = getSizesString(sizes.md);
    generatedSizes = `${queries.md} ${mdSize}, ${generatedSizes}`;
  }
  if (sizes?.lg) {
    const lgSize = getSizesString(sizes.lg);
    generatedSizes = `${queries.lg} ${lgSize}, ${generatedSizes}`;
  }

  return generatedSizes;
}

/**
 * Generate a proper string to be used in the sizes attribute. Our API allows
 * for both fractions, like "1 / 2", as well as custom strings like "50vw".
 *
 * @param {number | string} size
 * @returns {string}
 */
function getSizesString(size: number | string): string {
  return typeof size === 'string'
    ? size
    : `calc(80rem * ${getDecimalSize(size)})`;
}

/**
 * Create a decimal number between 0 and 1. Used when generating the sizes string.
 * Fixed to two decimal points. Trimming ending zeroes.
 *
 * @param {number} size
 * @returns {number}
 */
function getDecimalSize(size: number): number {
  return parseFloat(Math.max(0, Math.min(1, size)).toFixed(2));
}
