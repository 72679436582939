import AnimatedReveal from '@vcc-www/animated-reveal';
import { cssJoin, cssMerge } from '@volvo-cars/css/utils';
import React from 'react';
import { PromotionCard } from './PromotionsTextImage.props';
import { Track } from '@volvo-cars/tracking';
import { ResponsiveImage } from '@vcc-package/media';
import Disclaimer from '@vcc-www/disclaimer';
import styles from './PromotionsTextImage.module.css';

type Props = PromotionCard & {
  variant: 'vertical' | 'horizontal';
  className?: string;
  index: number;
  ctaVariant?: 'outline' | 'default' | 'text';
  twoRowLayout?: boolean;
  cards?: PromotionCard[];
};

export const Card = ({
  imageDesktop,
  imageMobile,
  title,
  cta,
  disclaimer,
  imageTablet,
  link,
  multilineText,
  text,
  variant,
  className,
  index,
  ctaVariant,
}: Props) => {
  return (
    <div
      className={cssMerge(
        'items-center bg-secondary',
        variant === 'horizontal' ? 'lg:flex-row lg:p-16' : 'lg:flex-col',
        className,
      )}
    >
      {imageMobile && imageDesktop && (
        <div className={styles.image}>
          <ResponsiveImage
            data-testid="promotions-text-image__image"
            className={cssJoin(
              'w-full aspect-16/9 object-cover',
              variant === 'horizontal' && 'lg:aspect-4/3',
            )}
            loading="lazy"
            sizes={{
              md: 1,
              lg: 0.5,
            }}
            images={{
              sm: imageMobile,
              md: imageTablet,
              lg:
                variant === 'horizontal'
                  ? imageMobile
                  : imageDesktop || imageTablet,
            }}
            alt={imageDesktop?.alt ?? ''}
          />
        </div>
      )}
      <div
        className={cssJoin(
          'w-full p-64 flex-col flex-grow flex-shrink',
          variant === 'horizontal' && 'lg:w-1/2',
        )}
      >
        <AnimatedReveal stagger={index + 2}>
          <h2 data-testid="promotions-text-image__title" className="heading-2">
            {title}
          </h2>
        </AnimatedReveal>
        <AnimatedReveal stagger={index + 3}>
          <p
            data-testid="promotions-text-image__text"
            className="text-secondary pb-32 pt-24 flex-shrink flex-grow"
          >
            {multilineText || text}
          </p>
        </AnimatedReveal>
        <div>
          {cta?.href && cta?.text && (
            <AnimatedReveal stagger={index + 4}>
              <Track
                eventLabel={`${cta.text}|${cta.href}`}
                eventAction="button|click"
                ga3={{ eventAction: 'click' }}
              >
                <a
                  data-testid="promotions-text-image__cta"
                  aria-label={cta.title}
                  href={cta.href}
                  onClick={cta.onClick}
                  data-color="accent"
                  className={cssJoin(
                    ctaVariant === 'outline' && 'button-outlined',
                    ctaVariant === 'default' && 'button-filled',
                    ctaVariant === 'text' && 'button-text',
                    !ctaVariant && 'button-outlined',
                  )}
                >
                  {cta.text}
                </a>
              </Track>
            </AnimatedReveal>
          )}
          {link?.href && link?.text && (
            <AnimatedReveal stagger={index + 5}>
              <Track
                eventLabel={`${link.text} | ${link.href}`}
                eventAction="link|click"
                ga3={{ eventAction: 'click' }}
              >
                <a
                  data-testid="promotions-text-image__link"
                  aria-label={link.title}
                  href={link.href}
                  target={link.target}
                  className="text-accent-blue"
                >
                  {link.text}
                </a>
              </Track>
            </AnimatedReveal>
          )}
        </div>
      </div>

      {!!disclaimer && (
        <Disclaimer
          extend={({ theme: { baselineGrid } }) => ({
            marginTop: 4 * baselineGrid,
            fromL: { display: 'none' },
          })}
          text={disclaimer}
        />
      )}
    </div>
  );
};
