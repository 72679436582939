'use client';
import React from 'react';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';
import AnimatedReveal from '@vcc-www/animated-reveal';
import PromotionsTextImageProps from './PromotionsTextImage.props';
import { cssJoin } from '@volvo-cars/css/utils';
import { Card } from './card';
import Markdown from '@vcc-www/markdown';

const numberOfPicturesDetected = (
  list: PromotionsTextImageProps['cards'] = [],
) => list.filter((card) => !!card.imageDesktop?.src).length;

const PromotionsTextImage: React.FC<
  React.PropsWithChildren<
    PromotionsTextImageProps & {
      /** cta variant */
      ctaVariant?: 'outline' | 'default' | 'text';
    }
  >
> = ({
  cards,
  disclaimer,
  leftAlignContent = false,
  alignImage = 'right',
  ctaVariant,
}) => {
  const twoRowLayout =
    cards?.length === 2 && numberOfPicturesDetected(cards) === 1;
  const singleCardWithImage =
    cards?.length === 1 && numberOfPicturesDetected(cards) === 1;
  const singleCardNoImage =
    cards?.length === 1 && numberOfPicturesDetected(cards) === 0;
  const horizontalLayout = cards?.length === 1 || twoRowLayout;
  const hasCardDisclaimers =
    !!cards?.[0]?.disclaimer || !!cards?.[1]?.disclaimer;

  return (
    <div>
      <div
        data-testid="promotions-text-image"
        className="gap-32 flex-col lg:flex-row"
      >
        {cards?.map((card, index) => {
          return (
            <AnimatedReveal key={index} stagger={index}>
              <Card
                text={card.text}
                multilineText={card.multilineText}
                cta={card.cta}
                title={card.title}
                imageDesktop={card.imageDesktop}
                imageTablet={card.imageTablet}
                imageMobile={card.imageMobile}
                className={cssJoin(
                  (!alignImage || alignImage === 'right') &&
                    horizontalLayout &&
                    'lg:flex-row-reverse',
                  singleCardNoImage ? 'w-1/2' : 'w-full',
                  (leftAlignContent || horizontalLayout) && !singleCardNoImage
                    ? 'lg:text-start'
                    : 'lg:text-center',
                  singleCardNoImage && 'w-full',
                  singleCardWithImage ? 'w-1/2' : 'w-full',
                  'text-center',
                )}
                variant={horizontalLayout ? 'horizontal' : 'vertical'}
                ctaVariant={ctaVariant}
                twoRowLayout={twoRowLayout}
                index={index}
              />
            </AnimatedReveal>
          );
        })}
      </div>
      {hasCardDisclaimers && (
        <div
          data-testid="promotions-text-image__card-disclaimer"
          className="hidden lg:flex w-full"
        >
          {cards?.length === 1 ? (
            <Markdown
              className="micro text-secondary md:text-center"
              markdown={cards?.[0]?.disclaimer || ''}
            />
          ) : (
            <>
              <Markdown
                className="micro text-secondary mt-64 pr-16 md:text-center"
                markdown={cards?.[0]?.disclaimer || ''}
              />
              <Markdown
                className="micro text-secondary pl-16 md:text-center"
                markdown={cards?.[1]?.disclaimer || ''}
              />
            </>
          )}
        </div>
      )}
      {disclaimer && (
        <div className="flex-row justify-center mt-32 w-full">
          <div
            className={cssJoin(maxContentWidth12Columns && 'w-full')} //more here mt-32
            data-testid="promotions-text-image__disclaimer"
          >
            <Markdown
              className="micro text-secondary md:text-center"
              markdown={disclaimer || ''}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PromotionsTextImage;
