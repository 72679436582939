import React from 'react';
import SectionContained from '@vcc-www/page-section/SectionContained';
import { PromotionsTextImage as SharedPromotionsTextImage } from '@vcc-package/discovery-utils';
import PromotionsTextImageProps from './PromotionsTextImage.props';
import { CustomWrapperProp } from '@vcc-www/page-section/CustomWrapperProp';

const PromotionsTextImage: React.FC<
  PromotionsTextImageProps & CustomWrapperProp
> = ({
  content,
  alignImage = 'right',
  multilineTextVariant = 'regular',
  customWrapper,
  ...params
}) => {
  const params_ = customWrapper
    ? { ...params }
    : {
        ...params,
        topInnerSpacing: 'none',
        bottomInnerSpacing: 'none',
        marginsOnMobile: true,
      };
  const Wrapper: React.FC<typeof params_> = (customWrapper ||
    SectionContained) as React.FC;

  return (
    <Wrapper {...params_}>
      <SharedPromotionsTextImage
        {...content}
        alignImage={alignImage}
        multilineTextVariant={multilineTextVariant}
      />
    </Wrapper>
  );
};

export default PromotionsTextImage;
